<template>
  <CListGroup>
    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.DOMAIN.label") }}
      <CBadge color="success" shape="pill"> {{ $t("plan.DOMAIN.value", [metadata.DOMAIN]) }}</CBadge>
    </CListGroupItem>

    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.DOMAIN_UP.label") }}
      <CBadge color="success" shape="pill"> {{ $t("plan.DOMAIN_UP.value", [metadata.DOMAIN_UP]) }}</CBadge>
    </CListGroupItem>

    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.TRAFFIC.label") }}
      <CBadge color="success" shape="pill"> {{ $t("plan.TRAFFIC.value", [metadata.TRAFFIC]) }}</CBadge>
    </CListGroupItem>


    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.CC.label") }}
      <CBadge color="primary" shape="pill"> {{ $t("plan.CC.value", [metadata.CC]) }}</CBadge>
    </CListGroupItem>


    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.DDOS.label") }}
      <CBadge color="primary" shape="pill"> {{ $t("plan.DDOS.value", [metadata.DDOS]) }}</CBadge>
    </CListGroupItem>


    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.WAF.label") }}
      <CBadge color="primary" shape="pill" v-if="metadata.WAF=='no'"> 不支持 </CBadge>
      <CBadge color="success" shape="pill" v-else> 支持  </CBadge>
    </CListGroupItem>

    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.WILDDOMAIN.label") }}
      <CBadge color="primary" shape="pill" v-if="metadata.WILDDOMAIN=='no'"> 不支持 </CBadge>
      <CBadge color="success" shape="pill" v-else> 支持 </CBadge>
    </CListGroupItem>

    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.CONCURRENCE.label") }}
      <CBadge color="success" shape="pill" v-if="metadata.CONCURRENCE=='unlimited'">{{ $t("Unlimited") }}</CBadge>
      <CBadge color="primary" shape="pill" v-else-if="metadata.CONCURRENCE">{{ $t("plan.CONCURRENCE.value", [metadata.CONCURRENCE] ) }}</CBadge>
      <CBadge color="gray" shape="pill" v-else> - </CBadge>
    </CListGroupItem>

    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.IPV6.label") }}
      <CBadge color="primary" shape="pill" v-if="metadata.IPV6=='no'"> 不支持 </CBadge>
      <CBadge color="success" shape="pill" v-else> 支持 </CBadge>
    </CListGroupItem>

    <CListGroupItem
      class="d-flex justify-content-between align-items-center"
    >
      {{ $t("plan.SUPPORT.label") }}
      <CBadge color="primary" shape="pill"> {{ $t("plan.SUPPORT.value", [metadata.SUPPORT]) }}</CBadge>
    </CListGroupItem>

  </CListGroup>
</template>

<script>


export default {
  name: "PlanMeta",
  data() {
    return {
      config: {},
      ssection: "https",
      labelText: {
        labelOn: "yes",
        labelOff: "no"
      }
    };
  },
  props: {
    plan: Object
  },
  computed: {
    metadata: function () {
      if(this.plan.metadata) {
        return JSON.parse(this.plan.metadata.replace(/'/gi, '"'))
      }
      return {}
      
    }
  },
  methods: {
  }
};
</script>