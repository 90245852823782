<template>
  <CRow>
    <CCol sm="6" md="3" v-for="p in products" :key="p.id" v-if="false && p.name!='DOMAIN' && p.name!='TRAFFIC'">
      <CCard>
        <CCardHeader>
          <CButton color="info" size="lg">{{ p.name }}</CButton>
        </CCardHeader>
        <CCardBody>
          <PlanMeta :plan="p" />
        </CCardBody>
        <CCardFooter>
          <stripe-checkout
            v-for="(plan, index) in p.plans"
            :key="plan.id"
            :plan="plan"
            :ref="'checkoutRef_' + plan.id"
            :pk="publishableKey"
            :items="[{plan: plan.id, quantity: 1}]"
            :successUrl="successUrl"
            :cancelUrl="cancelUrl"
            :clientReferenceId="clientReferenceId"
            :customerEmail="customerEmail"
            :type="paymentTypes"
          >
            <template slot="checkout-button">
              <CButton
                :to="{ path: '/domain/list' }"
                color="primary"
                v-if="plan.amount==0 && plan.interval_count==1"
              >
                <span>立即试用</span>
              </CButton>
              {{ p.name }}

              <CButton
                color="primary"
                v-if="plan.amount>0 && plan.interval_count==1"
                :data-shoppy-product="plan.nickname"
              >
                <CSpinner size="sm" v-if="hightlight==plan.id" />
                <span v-if="plan.amount>=0 && plan.interval_count==1">订购</span>
                ￥ {{ plan.amount }} / {{ plan.nickname  }}
              </CButton>
              <!--
              <CButton color="primary"  v-if="plan">
                
                <span v-if="plan.amount>=0 && plan.interval_count==1">订购</span> {{ plan.amount }}
              </CButton>
              <CButton color="primary" @click="checkout" v-else>
                  <span >Charge</span>
              </CButton>
              -->
            </template>
          </stripe-checkout>
        </CCardFooter>
      </CCard>
    </CCol>

    <CCol sm="6" md="6">
      <CCard>
        <CCardHeader>
          <CButton color="warning" size="lg">付款</CButton>
        </CCardHeader>
        <CCardBody>
          <CJumbotron color="gray">
            <h5>优惠付款：</h5>
            <CInput placeholder="请输入支付金额" append=".00" size="lg" v-model="chargeAmount">
              <template #prepend-content>￥</template>
            </CInput>
            <hr class="my-4" />
            <p>
              <CButton size="lg"  color="success" @click="charge('alipay')">
                <CSpinner size="sm" v-if="hightlight=='alipay'" />
                <CIcon size="lg" name="cibAlipay" />支付宝充值
              </CButton>
            </p>
            <p>
              <CButton size="lg"  color="success" @click="charge('wechat')">
                <CSpinner size="sm" v-if="hightlight=='wechat'" />
                <CIcon size="lg" name="cibWechat" />微信充值
              </CButton>
            </p>

            <p>
              <CAlert>* 充值后联系客服</CAlert>
            </p>
          </CJumbotron>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol sm="6" md="6">
      <CCard>
        <CCardHeader>
          <CButton color="warning" size="lg">数字币付款</CButton>
        </CCardHeader>
        <CCardBody>
          <CJumbotron color="gray">
            <h5>&nbsp;</h5>
            <CButton color="success" size="lg" href="https://shoppy.gg/product/AKKlzrT" target="_blank">数字币付款</CButton>
            
            <p>
              <CAlert>* 付款后联系客服</CAlert>
            </p>
          </CJumbotron>
        </CCardBody>
      </CCard>
    </CCol>
    <!--
    <CCol sm="6" md="4">
      <CCard>
        <CCardHeader>
          充值
        </CCardHeader>
        <CCardBody>
          <stripe-elements
            ref="elementsRef"
            :pk="publishableKey"
            :amount="chargeAmount"
            @token="tokenCreated"
            @loading="loading = $event"
          >
          </stripe-elements>

          <CButton color="primary" @click="submit">
              <span >充值 {{ chargeAmount }}</span>
          </CButton>
          充值后联系客服
        </CCardBody>
        <CCardFooter>
        </CCardFooter>
      </CCard>
    </CCol>
    -->

    <CModal title="二维码扫码" color="success" v-if="qrCode" :show.sync="qrCodeModal" id="qrCodeModal">
      <CRow>
        <CCol>
          <CAlert color="success">请使用微信扫描二维码进行支付</CAlert>
        </CCol>
        <CCol>
          <vue-qrcode :value="qrCode" />
        </CCol>
      </CRow>
    </CModal>
    <script type="application/javascript" defer src="https://shoppy.gg/api/embed.js"></script>

  </CRow>
</template>


<script>
import axios from "@/plugins/axios.js";
// import { StripeElements } from "vue-stripe-checkout";
import { StripeCheckout } from "vue-stripe-checkout";
import { mapGetters } from "vuex";
import PlanMeta from "./PlanMeta";
import VueQrcode from "vue-qrcode";

export default {
  name: "MemberPlans",
  components: {
    StripeCheckout,
    PlanMeta,
    VueQrcode
  },

  computed: {
    clientReferenceId () {
      if ( this.authInfo && this.authInfo.sub ) {
        return this.authInfo.sub
      }
      else {
        //this.authInfo

        return ''
      }
    },
    customerEmail() {
      if ( this.authInfo && this.authInfo.email ) {
        return this.authInfo.email
      }
      else {
        return ''
      }
    },
    ...mapGetters(["authInfo"]),
  },

  data() {
    return {
      products: {},
      items: [{ sku: "sku_H9d1YsTOROD47k", quantity: 1 }],
      loading: false,
      chargeAmount: 100,
      currency: "cny",
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      token: null,
      paymentTypes: "alipay",
      description: "Payment",
      successUrl: "/#/member/pay_success",
      cancelUrl: "/#/member/plans",
      qrCode: "",
      qrCodeModal: false,
      hightlight: ""
    };
  },
  methods: {
    checkout(planid) {
      this.hightlight = planid
      this.$refs['checkoutRef_' + planid][0].redirectToCheckout();
    },
    buy: function(p, plan) {
      let url = process.env.VUE_APP_API_URL + `/plan/product/${p.id}/plan/${plan.id}/checkout/`;
      axios
        .post(url, {
          action: "checkout"
        })
        .then(response => (this.session = response.data));
    },
    submit() {
      this.$refs.elementsRef.submit();
    },
    charge(chargeType) {
      this.hightlight = chargeType
      let stripe = window.Stripe(this.publishableKey);
      if (this.chargeAmount * 100 < 10000) {
        this.flash("单次充值金额不得少于100元", "error", {"timeout": 3000});
        return
      }

      let currency = this.currency
      let amount = this.chargeAmount * 100
      if (chargeType == "wechat" && this.currency == 'cny') {
        currency = 'hkd'
        amount = Math.floor( amount / 0.925 ) 
      }

      stripe
        .createSource({
          type: chargeType,
          amount: amount,
          currency: currency,
          redirect: {
            return_url: this.successUrl
          }
        })
        .then(result => {
          // handle result.error or result.source
          if (result.error) {
            this.flash(result.error.message, "error", { timeout: 5000 });
            return;
          }

          let source = result.source;

          if (source.type == "alipay") {
            window.location = source.redirect.url;
            return;
          } else if (source.type == "wechat") {
            var isWeixin = function() {
              //判断是否是微信
              var ua = navigator.userAgent.toLowerCase();
              return ua.match(/MicroMessenger/i) == "micromessenger";
            };
            if (
              !isWeixin() &&
              source.wechat.qr_code_url.startsWith("weixin://")
            ) {
              this.openQrCode(source.wechat.qr_code_url);
            } else {
              window.location = source.wechat.qr_code_url;
            }
          }
        });
    },
    showQrCode() {},

    // source:
    //   alipay: {statement_descriptor: null, native_url: null, data_string: null}
    //   amount: 1000
    //   client_secret: "src_client_secret_6FmkrHsEGTmL2ctnWwbU4clG"
    //   created: 1589781848
    //   currency: "hkd"
    //   flow: "redirect"
    //   id: "src_1Gk256HZJfVnEZkjWksxjZUV"
    //   livemode: false
    //   metadata: {}
    //   object: "source"
    //   owner: {address: null, email: null, name: null, phone: null, verified_address: null, …}
    //   redirect: {failure_reason: null, return_url: "https://console.suyundev.com/#/member/pay_success", status: "pending", url: "https://hooks.stripe.com/redirect/authenticate/src…secret=src_client_secret_6FmkrHsEGTmL2ctnWwbU4clG"}
    //   statement_descriptor: null
    //   status: "pending"
    //   type: "alipay"
    //   usage: "single_use"

    // amount: 1
    // client_secret: "src_client_secret_G7TaDMFZUIWjbp01euN1DBoZ"
    // created: 1589781948
    // currency: "hkd"
    // flow: "none"
    // id: "src_1Gk26iHZJfVnEZkjaURrFUxs"
    // livemode: false
    // metadata: {}
    // object: "source"
    // owner: {address: null, email: null, name: null, phone: null, verified_address: null, …}
    // statement_descriptor: null
    // status: "pending"
    // type: "wechat"
    // usage: "single_use"
    // wechat: {qr_code_url: "https://stripe.com/sources/test_source?source=src_…secret=src_client_secret_G7TaDMFZUIWjbp01euN1DBoZ"}
    // __proto__: Object
    //prepay_id: "wx18142121944978e4af51f7171910016100"
    //qr_code_url: "weixin://wxpay/bizpayurl?pr=wcBWcgq"
    //__proto__: Object
    //ALIPAY:  aud, cad, eur, gbp, hkd, jpy, nzd, sgd, usd, myr, cny.
    //The payment method `wechat` only supports the following currencies: aud, cad, eur, gbp, hkd, jpy, sgd, usd.

    // tokenCreated (token) {
    //   debugger;
    //   this.token = token;
    //   // for additional charge objects go to https://stripe.com/docs/api/charges/object
    //   this.charge = {
    //     type: 'alipay',
    //     source: token.card,
    //     amount: this.chargeAmount,
    //     description: this.description
    //   }
    //   this.sendTokenToServer(this.charge);
    // },
    sendTokenToServer() {
      //Not yes implemented
    },
    fetchData() {
      let self = this;
      axios
        .get("plan/product/")
        .then(function(response) {
          self.products = response.data.results;
          self.total_rows = response.data.count;
          self.showLoader = false;
        })
        .catch(function(error) {
          self.showLoader = false;
        });

      // axios
      //   .get("plan/subscription/")
      //   .then(function(response) {
      //     self.subscription = response.data;
      //   })
      //   .catch(function(error) {
      //     self.showLoader = false;
      //   });
    },

    loadStripe() {
      let stripeScript = document.createElement("script");
      stripeScript.setAttribute("src", "https://js.stripe.com/v3/");
      document.head.appendChild(stripeScript);
    },
    loadShoppy() {
      let stripeScript = document.createElement("script");
      stripeScript.setAttribute("src", "//shoppy.gg/api/embed.js");
      document.head.appendChild(stripeScript); 
    },
    openQrCode(qrCode) {
      this.qrCode = qrCode;
      this.qrCodeModal = true;
    }
  },

  mounted() {
    //this.fetchData();
    this.loadStripe();
    // this.loadShoppy();
  }
};
</script>
